import { AddressFragment, SmartAddressFragment } from "@gql-schema";
import { AddressFormatter } from "@utils/AddressFormatter";
import { StringHelper } from "@utils/StringHelper";

export const filterData = (data: ISelectKeyValues[], inputValue: string): ISelectKeyValues[] =>
    data.filter((i) => StringHelper.slugify(i.label).includes(StringHelper.slugify(inputValue)));

export interface ISelectKeyValues {
    value: any;
    label: string;
}

export const transformToOptions = <T>(data: T[], valueName: keyof T, labelName: keyof T): ISelectKeyValues[] => {
    return data.map((row) => {
        return {
            value: row[valueName],
            label: String(row[labelName]),
        };
    });
};

export const transformSmartAddressToOptions = (data: SmartAddressFragment[]): ISelectKeyValues[] => {
    return data.map((row: SmartAddressFragment): ISelectKeyValues => {
        const label = AddressFormatter.formatSmartAddress(row);

        return {
            value: String(row.id),
            label,
        };
    });
};

export const transformAddressToOptions = (data: AddressFragment[]): ISelectKeyValues[] => {
    return data.map((row: AddressFragment): ISelectKeyValues => {
        const label = AddressFormatter.formatAddress(row) ?? "";

        return {
            value: String(row.id),
            label,
        };
    });
};

// based on https://phpfashion.com/jak-overit-platne-ic-a-rodne-cislo
export const isValidBirthNumber = (value: string): boolean => {
    value = value.replace(/[^0-9]/g, "");
    if (value.length < 9 || value.length > 10) {
        return false;
    }

    let year = parseInt(value.substr(0, 2), 10);
    let month = parseInt(value.substr(2, 2), 10);
    const day = parseInt(value.substr(4, 2), 10);

    if (value.length === 9) {
        year += year < 54 ? 1900 : 1800;
    } else {
        const controlNumber = parseInt(value.substr(9, 1), 10);
        let divisionRest = parseInt(value.substr(0, 9), 10) % 11;
        if (divisionRest === 10) {
            divisionRest = 0;
        }
        // the rest after division and control number have to be the same
        if (divisionRest !== controlNumber) {
            return false;
        }
        year += year < 54 ? 2000 : 1900;
    }

    // the month can contain an extra 20, 50 or 70
    if (month > 70 && year > 2003) {
        month -= 70;
    } else if (month > 50) {
        month -= 50;
    } else if (month > 20 && year > 2003) {
        month -= 20;
    }

    return !(
        month === 0 ||
        month > 12 ||
        day === 0 ||
        day > 31 ||
        isNaN(new Date(`${year}-${month}-${day}`).getTime())
    );
};

// based on https://phpfashion.com/jak-overit-platne-ic-a-rodne-cislo
export const isValidCompanyIdNumber = (value: string): boolean => {
    value = value.replace(/[^0-9]/g, "");
    if (value.length === 7) {
        value = "0" + value;
    }
    if (value.length !== 8) {
        return false;
    }
    let sum = 0;
    for (let i = 0; i < 7; i++) {
        sum += parseInt(value[i], 10) * (8 - i);
    }
    const divisionRest = sum % 11;
    let lastDigitValue = 11 - divisionRest;
    if (divisionRest === 1) {
        lastDigitValue = 0;
    } else if (divisionRest === 0 || divisionRest === 10) {
        lastDigitValue = 1;
    }
    return parseInt(value[7], 10) === lastDigitValue;
};

export const isValidTaxCompanyIdNumber = (value: string): boolean => {
    value = value.replace(/[^0-9CZ]/g, "");
    const countryId = value.substr(0, 2).toUpperCase();
    value = value.substr(2);
    if (countryId === "CZ") {
        if (value.length < 8 || value.length > 11) {
            return false;
        }
        return value.length === 8 ? isValidCompanyIdNumber(value) : isValidBirthNumber(value);
    }
    return false;
};

// validators
export const requiredField = (value: string): string | undefined => (value ? undefined : "Povinné pole.");
export const requiredFirstName = (value: string): string | undefined => (value ? undefined : "Zadejte jméno.");
export const requiredSurname = (value: string): string | undefined => (value ? undefined : "Zadejte příjmení.");
export const passwordLengthValidator = (value?: string): string | undefined =>
    value && value.length > 7 ? undefined : "Heslo musí mít alespoň 8 znaků a číslic.";
export const isNumber = (value?: string): string | undefined =>
    value && !isNaN(Number(value)) ? undefined : "Zadejte číslo...";
export const greaterThanZero = (value?: string): string | undefined =>
    value && parseInt(value, 10) > 0 ? undefined : "Zadejte více než nula.";
export const lessThanWeek = (value?: string): string | undefined =>
    value && parseInt(value, 10) < 8 ? undefined : "Zadejte nejvýše 7 dní.";
export const lessThanTwoMonths = (value?: string): string | undefined =>
    value && parseInt(value, 10) < 61 ? undefined : "Zadejte nejvýš 60 dní.";
export const isBirthNumberFormat = (value?: string): string | undefined =>
    value ? (isValidBirthNumber(value) ? undefined : "Zadejte platný formát rodného čísla...") : undefined;
